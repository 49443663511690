<template>
  <div>
    <v-row>
      <!--      desktop view-->
      <v-col cols="12" lg="9" md="6">
        <v-row>
          <!--          <v-col cols="12" class="hidden-md-and-up">-->
          <!--            <v-card elevation="0"-->
          <!--                    color="commission-content-holder full-height primary white&#45;&#45;text text-center pa-2 mb-3">-->
          <!--              <v-card-text>-->
          <!--                <v-row justify="center" no-gutters>-->
          <!--                  <v-col cols=9 class="mb-3">-->
          <!--                    <label class="white&#45;&#45;text">{{ $t(`label.commissionToRedeem`) }}</label>-->
          <!--                  </v-col>-->
          <!--                  <v-col cols=9 class="mb-0">-->
          <!--                    <v-form ref="redeemForm">-->
          <!--                      <app-form-field :customClass="'commission-redeem-field input-field elevation-0 hide-details'"-->
          <!--                                      :enabledLabel="false" v-model.trim="redeemCommissionAmt" :readonly='true'-->
          <!--                                      :prefix="currencySymbolMapp(currentCurrency)"-->
          <!--                                      :rules="validator.requiredRules()"></app-form-field>-->
          <!--                    </v-form>-->
          <!--                  </v-col>-->
          <!--                  <v-col cols=9>-->
          <!--                    <app-button class="dialog-button" :action="redeemCommissionConfirmationDialog"-->
          <!--                                :title="$t(`button.redeem`)"></app-button>-->
          <!--                  </v-col>-->
          <!--                </v-row>-->
          <!--              </v-card-text>-->
          <!--            </v-card>-->
          <!--            <v-card class="hidden-md-and-up mobile-balance-card">-->
          <!--              <div class="mobile-balance-div">-->
          <!--                <div>-->
          <!--                  <span style="color:#FFFFFF">{{ $t(`mobileMenuTitle.availableCom`) }}</span>-->
          <!--                  <div class="balance-span">-->
          <!--                    <span>{{ redeemCommissionAmt | currency(currentCurrency) }}</span>-->
          <!--                    &lt;!&ndash;                    <v-form ref="redeemForm">&ndash;&gt;-->
          <!--                    &lt;!&ndash;                       <app-form-field&ndash;&gt;-->
          <!--                    &lt;!&ndash;                        :customClass="'commission-redeem-field input-field elevation-0 hide-details'"&ndash;&gt;-->
          <!--                    &lt;!&ndash;                        :enabledLabel="false"&ndash;&gt;-->
          <!--                    &lt;!&ndash;                        v-model.trim="redeemCommissionAmt"&ndash;&gt;-->
          <!--                    &lt;!&ndash;                        :readonly="true"&ndash;&gt;-->
          <!--                    &lt;!&ndash;                        :prefix="currencySymbolMapp(currentCurrency)"&ndash;&gt;-->
          <!--                    &lt;!&ndash;                        :rules="validator.requiredRules()"&ndash;&gt;-->
          <!--                    &lt;!&ndash;                      ></app-form-field>&ndash;&gt;-->
          <!--                    &lt;!&ndash;                    </v-form>&ndash;&gt;-->
          <!--                  </div>-->
          <!--                  <div>-->
          <!--                    <v-btn class="dialog-button mobile-redeem-btn" @click="redeemCommissionConfirmationDialog">{{ $t(`label.claim`) }}</v-btn>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </v-card>-->
          <!--          </v-col>-->

          <v-col cols="12" lg="4" md="4" sm="6" class="hidden-md-and-down">
            <v-row no-gutters align="end">
              <v-col cols="12">
                <label>{{ $t(`label.todayCommission`) }}</label>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-card color="primary white--text text-left py-2 pl-6 mb-3">
                  {{ $t(`label.points`) }}
                  {{ overview.commission_balance }}
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters align="end">
              <v-col cols="12">
                <label>{{ $t(`label.monthCommission`) }}</label>
              </v-col>
            </v-row>
            <v-row no-gutters align="start">
              <v-col cols="12">
                <v-card color="primary white--text text-left py-2 pl-6">
                  {{ $t(`label.points`) }}
                  {{ overview.commission_month }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" class="hidden-md-and-down">
            <v-row no-gutters align="end">
              <v-col cols="6">
                <v-card elevation="0" color="border-left-radius primary white--text text-center pa-2 mb-3 mr-1">
                  {{ $t(`label.total`) }}
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card elevation="0" color="border-right-radius primary white--text text-center pa-2 mb-3 mr-1">
                  {{ totalPoint }}
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6">
                <v-card elevation="0" color="border-left-radius title_color2 white--text text-center pa-2 mb-3 mr-1">{{ stringFormat($t(`label.tier`), 1) }}</v-card>
              </v-col>
              <v-col cols="6">
                <v-card elevation="0" color="border-right-radius title_color2 white--text text-center pa-2 mb-3 mr-1">
                  {{ overview.downline_lv1 }}
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters align="end">
              <v-col cols="6">
                <v-card elevation="0" color="border-left-radius title_color2 white--text text-center pa-2 mb-3 mr-1">{{ stringFormat($t(`label.tier`), 2) }}</v-card>
              </v-col>
              <v-col cols="6">
                <v-card elevation="0" color="border-right-radius title_color2 white--text text-center pa-2 mb-3 mr-1">
                  {{ overview.downline_lv2 }}
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters align="start">
              <v-col cols="6">
                <v-card elevation="0" color="border-left-radius title_color2 white--text text-center pa-2 mb-3 mr-1">{{ stringFormat($t(`label.tier`), 3) }}</v-card>
              </v-col>
              <v-col cols="6">
                <v-card elevation="0" color="border-right-radius title_color2 white--text text-center pa-2 mb-3 mr-1">
                  {{ overview.downline_lv3 }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="4" md="12">
            <v-card elevation="0" color="commission-content-holder full-height primary white--text text-center pa-2 mb-3">
              <v-card-text>
                <v-row justify="center" no-gutters>
                  <v-col cols="9" class="mb-3">
                    <label class="white--text">{{ $t(`mobileMenuTitle.availableCom`) }}</label>
                  </v-col>
                  <v-col cols="9" class="mb-0">
                    <v-form ref="redeemForm">
                      <app-form-field
                        :customClass="'commission-redeem-field input-field elevation-0 hide-details'"
                        :enabledLabel="false"
                        v-model.trim="redeemCommissionAmt"
                        :readonly="true"
                        :prefix="currencySymbolMapp(currentCurrency)"
                        :rules="validator.requiredRules()"
                      ></app-form-field>
                    </v-form>
                  </v-col>
                  <v-col cols="9">
                    <app-button
                      style="background-color: var(--v-newMobileSecondary-base) !important;"
                      class="mobile-redeem-btn dialog-button "
                      :action="this.redeemCommissionConfirmationDialog"
                      :title="$t(`button.redeem`)"
                    ></app-button>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="hidden-md-and-down">
            <v-row no-gutters>
              <v-col v-if="!$vuetify.breakpoint.xsOnly && currentCurrency == Currencies.BDT" cols="12" class="border-referralbonus">
                <v-col cols="12" style="display: inline-flex; padding: 12px 24px 0px !important;">
                  <label class="mx-auto ml-0 pa-1" style="width: fit-content; font-weight: bold">
                    {{ $t(`label.referralBonus`) }}
                  </label>
                  <label class="ml-0 pa-1" style="width: fit-content; font-weight: bold">
                    {{ $t(`label.expiredDate`) }} : {{ downlineDetail.is_expired == true ? $t(`label.expired`) : downlineDetail.expired_at }}
                  </label>
                </v-col>
                <v-col cols="12" style="display: flex;" v-if="!$vuetify.breakpoint.xsOnly">
                  <v-col cols="6">
                    <v-card elevation="0" class="commission-content-card" color="commission-content-holder primary pa-2 mb-3 col-5">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <label class="mx-auto ml-0 mr-6 pa-1 white--text" style="width: 100px">{{ $t(`label.freeBonus`) }}</label>
                            <v-card class="font-weight-bold justify-start d-flex" color="title_color2 text-center pa-1 ml-0 mx-auto input-card">
                              {{ decimalTruncate(downlineDetail.bonus_amount, 2) }}
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <label class="mx-auto ml-0 mr-6 pa-1 white--text" style="width: 100px">{{ $t(`label.deposit`) }}</label>
                            <v-card elevation="0" class="font-weight-bold justify-start d-flex" color="title_color2 text-center pa-1 mx-auto ml-0 input-card">
                              {{ decimalTruncate(downlineDetail.current_deposit, 2) }} /
                              {{ decimalTruncate(downlineDetail.required_deposit, 2) }}
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <label class="mx-auto ml-0 mr-6 pa-1 white--text" style="width: 100px">{{ $t(`label.turnover`) }}</label>
                            <v-card elevation="0" class="font-weight-bold justify-start d-flex" color="title_color2 text-center pa-1 mx-auto ml-0 input-card">
                              {{ decimalTruncate(downlineDetail.current_valid_bet, 2) }} /
                              {{ decimalTruncate(downlineDetail.required_valid_bet, 2) }}
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card elevation="0" class="commission-content-card" color="commission-content-holder base pa-2 mb-3 col-7">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" class="text-center">
                            <label class="--v-primary-base" style="font-size: 16px">{{ $t(`label.youReferralStatus`) }}</label>
                            <div style="display: flex" class="mt-10 justify-center">
                              <div style="width: fit-content; border-right: 2px dotted rgba(8,8,8);" class="mt-6 pa-3">
                                <div class="text-center">
                                  <label class="referral-text">{{ uplineReferral.accepted_referral }}</label>
                                </div>
                                <div>
                                  <label>{{ $t(`label.friendAccepted`) }}</label>
                                </div>
                              </div>
                              <div style="width: fit-content" class="mt-6 pa-3">
                                <div class="text-center">
                                  <label class="referral-text">{{ uplineReferral.current_referral }}</label>
                                </div>
                                <div>
                                  <label>{{ $t(`label.friendCompleted`) }}</label>
                                </div>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-col>
                <v-col cols="12" style="justify-content: end; display: flex;">
                  <v-row style="justify-content: end; padding: 12px 24px 12px !important;">
                    <v-btn style="width: 200px;" class="dialog-button subtitle-1 full-width text-capitalize pa-2" @click="redirectDownLineReport()">
                      {{ $t(`button.downLine`) }}
                    </v-btn>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <!--      desktop view-->
      <v-col cols="12" lg="3" md="6" class="hidden-sm-and-down">
        <v-card elevation="0" color="commission-content-holder base pa-2 mb-3 full-height" style="height: 100%">
          <v-card-text>
            <v-row no-gutters justify="center">
              <v-col cols="12" lg="12" md="4" class="text-center">
                <label color="primary">
                  {{ $t(`label.id`) }} :
                  <span class="font-weight-bold">{{ overview.member_id }}</span>
                </label>
              </v-col>
              <v-col cols="12" lg="12" md="8" class="mb-0 text-center">
                <label color="primary">
                  {{ $t(`field.referralCode`) }} :
                  <span class="font-weight-bold">{{ overview.my_referral_code }}</span>
                </label>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <vue-qr-code v-if="overview.my_referral_code" class="qrCode" style="display: flex; justify-content: center" :text="share_url" :size="180"></vue-qr-code>
              </v-col>
              <v-col cols="12" class="mb-0">
                <v-row no-gutters justify="center">
                  <v-col cols="12" lg="12" md="8">
                    <!-- <v-img class="mr-2" src="/static/svg/fb_btn.svg" @click="share()"> </v-img> -->
                    <v-btn class="full-width font-weight-bold pa-2" :class="'buttonPrimary theme-button theme--light subtitle-1'" @click="share()">
                      <v-icon class="mr-2">share</v-icon>
                      {{ webShareApiSupported ? $t(`button.shareNow`) : $t(`button.copyRef`) }}
                    </v-btn>
                  </v-col>
                  <!-- <v-col cols=6>
                         <v-img class="mr-2" src="/static/svg/whatapps_btn.svg"> </v-img>
                     </v-col> -->
                </v-row>
                <!-- <v-row no-gutters justify="center" class="pt-3">
                       <v-col cols=6>
                           <v-img class="mr-2" src="/static/svg/line_btn.svg"> </v-img>
                       </v-col>
                       <v-col cols=6>
                           <v-img class="mr-2" src="/static/svg/twitter_btn.svg"> </v-img>
                       </v-col>
                   </v-row> -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" v-if="$vuetify.breakpoint.xsOnly">
        <v-row>
          <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12" md="7" sm="7">
            <v-card elevation="0" class="commission-content-holder base pa-2 mb-3 full-height">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="10" :md="7" class="d-flex flex-column justify-center" style="margin:auto">
                    <v-row no-gutters>
                      <v-col class="d-flex justify-center font-weight-bold text-right">{{ $t(`label.id`) }}: {{ overview.member_id }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col class="d-flex justify-center font-weight-bold text-right">{{ $t(`field.referralCode`) }}: {{ overview.my_referral_code }}</v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex justify-center">
                    <vue-qr-code v-if="overview.my_referral_code" class="" :text="share_url" :size="250"></vue-qr-code>
                  </v-col>
                  <v-col cols="12" md="12" sm="8" class="mb-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="9" lg="6">
                        <!-- <v-img class="mr-2" src="/static/svg/fb_btn.svg" @click="share()"> </v-img> -->
                        <v-btn class="dialog-button subtitle-1 full-width text-capitalize pa-2" @click="share()">
                          <v-icon class="mr-2">mdi-share-variant</v-icon>
                          {{ webShareApiSupported ? $t(`button.shareNow`) : $t(`button.copy_ref`) }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="hidden-lg-and-up">
        <v-row no-gutters>
          <v-col v-if="currentCurrency == Currencies.BDT" cols="12" class="border-referralbonus">
            <v-col cols="12" style="display: inline-flex; padding: 12px 24px 0px !important;">
              <label class="mx-auto ml-0 pa-1" style="width: fit-content; font-weight: bold">
                {{ $t(`label.referralBonus`) }}
              </label>
              <label class="ml-0 pa-1" style="width: fit-content; font-weight: bold">
                {{ $t(`label.expiredDate`) }} : {{ downlineDetail.is_expired == true ? $t(`label.expired`) : downlineDetail.expired_at }}
              </label>
            </v-col>
            <v-col cols="12" style="display: flex; flex-flow: wrap;">
              <v-col cols="12" sm="6">
                <v-card elevation="0" class="commission-content-card" color="commission-content-holder primary pa-2 mb-3 col-5">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <label class="mx-auto ml-0 mr-6 pa-1 white--text" style="width: 100px">{{ $t(`label.freeBonus`) }}</label>
                        <v-card class="font-weight-bold justify-start d-flex" color="title_color2 text-center pa-1 ml-0 mx-auto input-card">
                          {{ decimalTruncate(downlineDetail.bonus_amount, 2) }}
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <label class="mx-auto ml-0 mr-6 pa-1 white--text" style="width: 100px">{{ $t(`label.deposit`) }}</label>
                        <v-card elevation="0" class="font-weight-bold justify-start d-flex" color="title_color2 text-center pa-1 mx-auto ml-0 input-card">
                          {{ decimalTruncate(downlineDetail.current_deposit, 2) }} /
                          {{ decimalTruncate(downlineDetail.required_deposit, 2) }}
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <label class="mx-auto ml-0 mr-6 pa-1 white--text" style="width: 100px">{{ $t(`label.turnover`) }}</label>
                        <v-card elevation="0" class="font-weight-bold justify-start d-flex" color="title_color2 text-center pa-1 mx-auto ml-0 input-card">
                          {{ decimalTruncate(downlineDetail.current_valid_bet, 2) }} /
                          {{ decimalTruncate(downlineDetail.required_valid_bet, 2) }}
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6">
                <v-card elevation="0" class="commission-content-card" color="commission-content-holder base pa-2 mb-3 col-7">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <label class="--v-primary-base" style="font-size: 16px">{{ $t(`label.youReferralStatus`) }}</label>
                        <div style="display: flex" class="mt-10 justify-center">
                          <div style="width: fit-content; border-right: 2px dotted rgba(8,8,8);" class="mt-6 pa-3">
                            <div class="text-center">
                              <label class="referral-text">{{ uplineReferral.accepted_referral }}</label>
                            </div>
                            <div>
                              <label>{{ $t(`label.friendAccepted`) }}</label>
                            </div>
                          </div>
                          <div style="width: fit-content" class="mt-6 pa-3">
                            <div class="text-center">
                              <label class="referral-text">{{ uplineReferral.current_referral }}</label>
                            </div>
                            <div>
                              <label>{{ $t(`label.friendCompleted`) }}</label>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-col>
            <v-col cols="12" style="justify-content: end; display: flex;">
              <v-row style="justify-content: end; padding: 12px 24px 12px !important;">
                <v-btn style="width: 200px;" class="dialog-button subtitle-1 full-width text-capitalize pa-2" @click="redirectDownLineReport()">
                  {{ $t(`button.downLine`) }}
                </v-btn>
              </v-row>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="display: flex;gap: 0px 1.5rem;justify-content: space-evenly;">
        <div class="summaryRouter-link" :class="this.$route.name.toLowerCase() == routeName.REFERRAL_SUMMARY_REPORT ? 'summaryRouter-link-selected' : ''">
          <router-link :to="{ name: routeName.REFERRAL_SUMMARY_REPORT, params: { lang: this.$route.params.lang } }" style="text-decoration: unset !important">
            <span>{{ $t(`button.referralReport`) }}</span>
          </router-link>
        </div>
        <div class="summaryRouter-link" :class="this.$route.name.toLowerCase() == routeName.REFERRAL_SUMMARY_HISTORY ? 'summaryRouter-link-selected' : ''">
          <router-link :to="{ name: routeName.REFERRAL_SUMMARY_HISTORY, params: { lang: this.$route.params.lang } }" style="text-decoration: unset !important">
            <span>{{ $t(`button.redeemHistory`) }}</span>
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" class="refSummaryContainer">
        <router-view></router-view>
      </v-col>
    </v-row>
    <v-snackbar class="pa-0" style="z-index:1000 !important;top:7%" light timeout="1000" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'check_circle' : 'cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
  </div>
</template>

<script>
import VueQrCode from 'vue-qrcode-component'
import AppLoginEmbedded from '@/components/member/loginEmbedded.vue'
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper, locale, errorCodeHelper, formValidator } from '@/util'
import { Currencies, SHARED } from '@/constants/constants'
import _ from 'lodash'
import { REFERRAL_GET_DOWNLINES, REFERRAL_GET_OVERVIEW, REFERRAL_POST_COMMISSION, REFERRAL_RESET_COMMISSION } from '@/store/referral.module'
import stringFormat from 'string-format'
import currencySymbolMapp from 'currency-symbol-map'
import { AFFILIATE_DOWNLINE_DETAIL, AFFILIATE_UPLINE_REFERRAL } from '@/store/affiliate.bonus.module'

export default {
  name: 'referralSummary',
  components: {
    VueQrCode,
    AppLoginEmbedded
  },
  data: () => ({
    dateOfBirthMenu: false,
    stringFormat: stringFormat,
    currencySymbolMapp: currencySymbolMapp,
    language: uiHelper.getLanguage(),
    currentCurrency: uiHelper.getCurrency(),
    validator: formValidator,
    shared: SHARED,
    selectedTab: 'tier',
    dates: ['', ''],
    overview: {},
    redeemCommissionAmt: 0.0,
    share_url: '',
    alertShow: false,
    alertSuccess: false,
    alertMessage: '',
    routeName: ROUTE_NAME
  }),
  created() {
    this.metaTag()
    this.getReferralOverview()
    if (!_.isEmpty(this.$store.state.referral.overview)) {
      this.referCode = this.$store.state.referral.overview.my_referral_code
      this.referLink = uiHelper.getHostname() + '?refer_code=' + this.referCode
    } else {
      this.$store.dispatch(`${REFERRAL_GET_OVERVIEW}`)
      this.$store.dispatch(`${REFERRAL_GET_DOWNLINES}`)
    }
    // this.getDownlineDetail()
    // this.getUplineReferral()
    if (_.isEmpty(this.$store.state.affiliateBonus.affiliateDownlineDetail)) {
      this.$store.dispatch(`${AFFILIATE_DOWNLINE_DETAIL}`)
    }

    if (_.isEmpty(this.$store.state.affiliateBonus.affiliateUplineReferral)) {
      this.$store.dispatch(`${AFFILIATE_UPLINE_REFERRAL}`)
    }
  },
  mounted() {
    this.$refs.redeemForm.reset()
  },
  computed: {
    Currencies() {
      return Currencies
    },
    currentDate() {
      return new Date()
    },
    referralOverview() {
      return this.$store.state.referral.overview
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    totalPoint() {
      return this.overview.downline_lv1 + this.overview.downline_lv2 + this.overview.downline_lv3
    },
    redeemCommissionDetailResponseComplete() {
      return this.$store.state.referral.redeemCommissionResponse.complete
    },
    webShareApiSupported() {
      return navigator.share
    },
    uplineReferral() {
      return this.$store.state.affiliateBonus.affiliateUplineReferral
    },
    downlineDetail() {
      return this.$store.state.affiliateBonus.affiliateDownlineDetail
    }
  },
  watch: {
    referralOverview() {
      this.overview = this.$store.state.referral.overview
      if (window.location.host.toLowerCase() === '897909.com') {
        this.share_url = 'https://babu88.co' + '?refer_code=' + this.overview.my_referral_code
      } else {
        // If not, construct the share URL normally
        this.share_url = uiHelper.getHostname() + '?refer_code=' + this.overview.my_referral_code
      }
      this.redeemCommissionAmt = this.$store.state.referral.overview.commission_balance.toFixed(2)
    },
    redeemCommissionDetailResponseComplete() {
      let response = this.$store.state.referral.redeemCommissionResponse
      if (response.complete) {
        this.redeemCommissionResponseCompleteDialog(response)
        this.$store.dispatch(`${REFERRAL_RESET_COMMISSION}`)
      }
    }
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      const Linksenin = `https://${domain}/en-IN${cleanPath}`
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`

      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    },
    openMsgDialog(selectedMsg) {
      this.msgDialog = {
        show: true,
        msg: selectedMsg.message,
        title: selectedMsg.subject
      }
    },
    closeMsgDialog() {
      this.msgDialog = {
        show: false
      }
    },
    getReferralOverview() {
      if (this.isLoggedIn) this.$store.dispatch(`${REFERRAL_GET_OVERVIEW}`)
    },
    redeemCommissionConfirmationDialog() {
      let self = this
      if (this.$refs.redeemForm.validate()) {
        let dialog = this.initializePageDialogProperty()
        dialog.title = locale.getMessage(`label.redeemConfirmation`)
        dialog.message.push(this.stringFormat(locale.getMessage(`message.ConfirmCommission`), self.$options.filters.currency(this.redeemCommissionAmt, this.currentCurrency)))
        dialog.button.push({
          title: locale.getMessage(`label.confirm`),
          action: () => this.redeemCommission()
        })
        this.openPageDialog(dialog)
      }
    },
    redeemCommission() {
      let obj = { amount: this.redeemCommissionAmt }
      this.$store.dispatch(`${REFERRAL_POST_COMMISSION}`, {
        obj
      })
    },
    redeemCommissionResponseCompleteDialog(response) {
      this.closePageDialog()
      if (response.code != 0 && !response.success) {
        this.$parent.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
      } else {
        this.$parent.$parent.openAlert(true, locale.getMessage(`message.commissionRedeemSuccess`))
      }
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    },
    share() {
      const sharing = {
        title: 'Refer a friend now and earn commission',
        text: 'Your referral code is ' + this.overview.my_referral_code + '.Refer a friend now and earn commission now! ',
        url: this.share_url
      }
      if (this.webShareApiSupported) {
        navigator.share(sharing)
      } else {
        let $f = this
        this.$copyText(this.share_url).then(function(e) {
          $f.alertMessage = 'Referral Link Copied!'
          $f.alertSuccess = true
          $f.alertShow = true
        })
      }
    },
    getUplineReferral() {
      this.$store.dispatch(`${AFFILIATE_UPLINE_REFERRAL}`)
    },
    getDownlineDetail() {
      this.$store.dispatch(`${AFFILIATE_DOWNLINE_DETAIL}`)
    },
    decimalTruncate(num, fixed) {
      if (num == null) {
        num = 0
      }
      let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?')
      return parseFloat(num.toString().match(re)[0])
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    redirectDownLineReport() {
      this.$router.push({
        name: ROUTE_NAME.REFERRAL_DOWNLINE_REPORT,
        params: { lang: this.$route.params.lang }
      })
    }
  }
}
</script>

<style lang="scss">
.summaryRouter-link {
  display: flex;
  min-width: 100px;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  color: #000000;
  border-radius: 15px;
  padding: 12px 16px;
}
.downline-btn {
  background-color: #d9d9d9 !important;
}
.summaryRouter-link-selected {
  color: var(--v-newMobilePrimary-base);
  background-color: #333333;
}
.balance-span {
  color: #ffce01 !important;
  font-size: 1.5rem;
  margin-top: 10px;
}
.mobile-redeem-btn {
  background-color: var(--v-newMobileSecondary-base) !important;
  color: #ffffff !important;
  margin-top: 10px;
  width: 150px;
  text-transform: unset !important;
}
.mobile-balance-card.v-card {
  background-color: #333333;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 135px;
  margin-left: 60px;
  margin-right: 60px;
  border-radius: 10px;
  .mobile-balance-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.border-left-radius {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.border-right-radius {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.commission-redeem-field {
  .v-input__slot {
    background-color: #fff !important;
  }
}

.media-btn {
  height: 50px;
  width: 100px;
}

.commission-content-holder {
  border-radius: 20px !important;
}

.v-btn.theme-button {
  // background: #FDDD04;
  text-transform: capitalize;
  border-radius: 10px;
  height: auto;
  font-size: 16px;
}

.commission-content-card {
  min-width: 252px;
  max-width: 332px;
  width: 100%;
  height: 250px;

  @media (max-width: 600px) {
    max-width: 100%;
  }

  .referral-text {
    font-size: 50px;
    font-weight: 700;
  }
}

.border-referralbonus {
  border: 2px solid #333333;
  border-radius: 10px;
  margin-top: 10px;
}
</style>
